import { reactive, ref, useContext, toRefs, inject } from '@nuxtjs/composition-api'
import { useLoginInfo } from '@/hooks/useLoginInfo'
import { requiredInput } from '@/utils/validate'


export default {
  __name: 'c2mRequireDialog',
  setup(__props, { expose }) {

const context = useContext()
const $message = inject('$message')
const state = reactive({
  dialogVisible: false,
  loading: false
})

const { dialogVisible } = toRefs(state)

const { userInfo } = useLoginInfo()

const columns = ref([
  {
    label: '商品编号',
    align: 'center',
    prop: 'resourceId'
  },
  {
    label: '规格',
    align: 'center',
    prop: 'spec'
  },
  {
    label: '尺寸信息',
    align: 'center',
    prop: 'sizeInfo'
  },
  {
    label: '质量信息',
    align: 'center',
    prop: 'qualityInfo'
  },
  {
    label: '仓库',
    align: 'center',
    prop: 'repositoryName'
  }
])

const goodsData = ref([])

const openDialog = async (query) => {
  editForm.goodsList = []
  const { data, statusCode } = await context.$api.goods.getSearchDetails(query)
  if (statusCode == 200) {
    state.loading = false
    state.dialogVisible = true
    goodsData.value = data.splice(0, 1)
  }
}

const editForm = reactive({
  goodsList: []
})

const rules = {
  len: requiredInput('请输入长', 'blur'),
  width: requiredInput('请输入宽', 'blur')
}

const getInitGoodsList = () => {
  return { resourceId: '', thickness: '', width: undefined, len: undefined }
}

const handleAdd = () => {
  const parmas = {
    ...getInitGoodsList()
  }
  parmas.thickness = goodsData.value[0].spec.includes('*') ? goodsData.value[0].spec.split('*')[0] : goodsData.value[0].spec
  if (editForm.goodsList.length == 0) {
    parmas.resourceId = goodsData.value[0].resourceId
  } else {
    parmas.resourceId = `${goodsData.value[0].resourceId}-Q${editForm.goodsList.length}`
  }
  editForm.goodsList.push(parmas)
}

const handleDelete = (index) => {
  editForm.goodsList.splice(index, 1)
}

const getParams = () => {
  const { listedPrice, qualityInfo, repositoryName, sizeInfo, spec, resourceId } = goodsData.value[0]
  const mainList = {
    listedPrice,
    qualityInfo,
    repositoryName,
    sizeInfo,
    spec,
    resourceId,
    status: 0,
    type: 1,
    buyerCorpId: userInfo.value.loginCorpId,
    buyerCorpName: userInfo.value.corpName,
    buyerOptUser: userInfo.value.optUserName
  }
  const detail = editForm.goodsList.map((_) => {
    return {
      ..._,
      listedPrice,
      qualityInfo,
      repositoryName,
      sizeInfo,
      spec: `${_.thickness}*${_.width}*${_.len}`,
      status: 0,
      type: 2,
      buyerCorpId: userInfo.value.loginCorpId,
      buyerCorpName: userInfo.value.corpName,
      buyerOptUser: userInfo.value.optUserName
    }
  })
  return [mainList, ...detail]
}

const formRef = ref(null)
const onSubmit = () => {
  if (editForm.goodsList.length == 0) {
    return $message.info('请新增明细')
  }
  formRef.value.validate(async (valid) => {
    if (valid) {
      try {
        state.loading = true
        const { data, statusCode } = await context.$api.smallSize.addDemand(getParams())
        state.loading = false
        if (statusCode == 200) {
          state.dialogVisible = false
          $message.success('发布成功')
        }
      } catch (error) {
        state.loading = false
        $message.info('网络异常')
      }
    }
  })
}

expose({
  openDialog,
  dialogVisible
})

return { __sfc: true,context, $message, state, dialogVisible, userInfo, columns, goodsData, openDialog, editForm, rules, getInitGoodsList, handleAdd, handleDelete, getParams, formRef, onSubmit }
}

}